import React from "react";
import ServicesBoilerPlate from "../../components/common/Services/ServicesBoilerPlate";
import MEPBanner from "../../assets/MECHANICAL, ELECTRICAL & PLUMBING (MEP)/MEPBanner.png";
import MEPComponent from "../../components/services/MEPComponent";
import { Helmet } from "react-helmet";

const MEP = () => {
  return (
    <>
      <Helmet>
        <title>
          MEP Services: Empowering Environments with Arcustyle's Excellence
        </title>
        <meta
          name="description"
          content="Empower your project with MEP excellence by Arcustyle. From vendor sourcing to ongoing support, our MEP services seamlessly integrate infrastructure into your architectural vision, ensuring the smooth operation of your spaces.
"
        />
      </Helmet>
      <ServicesBoilerPlate
        bannerImage={MEPBanner}
        componentToRender="MEP"
        component={<MEPComponent />}
      />
    </>
  );
};

export default MEP;
