import React, { useState } from "react";
import ServicesBoilerPlate from "../../components/common/Services/ServicesBoilerPlate";
import ArchitechtureBanner from "../../assets/Architecture/ArchitechtureBanner.png";
import InteriorBanner from "../../assets/Interior Design/InteriorBanner.png";
import ArchitectureInteriorComponent from "../../components/services/ArchitectureInteriorComponent";
import { Helmet } from "react-helmet";

const ArchitectureInteriorDesign = () => {
  const [selectedServices, setSelectedServices] = useState("architecture");

  return (
    <>
      <Helmet>
        <title>
          Architecture Services: Innovative Design and Precision by Arcustyle
        </title>
        <meta
          name="description"
          content="Arcustyle's Architecture Services seamlessly blend precision and vision to shape the future of spaces. From in-depth analysis to stunning 3-D representations, our comprehensive approach ensures the crafting of tomorrow's architectural masterpieces."
        />
      </Helmet>
      {selectedServices === "architecture" ? (
        <ServicesBoilerPlate
          bannerImage={ArchitechtureBanner}
          componentToRender="Architecture&Interior"
          component={
            <ArchitectureInteriorComponent
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
            />
          }
        />
      ) : (
        <ServicesBoilerPlate
          bannerImage={InteriorBanner}
          componentToRender="Architecture&Interior"
          component={
            <ArchitectureInteriorComponent
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
            />
          }
        />
      )}
    </>
  );
};

export default ArchitectureInteriorDesign;
