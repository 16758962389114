import { useEffect } from "react";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import IntroducingSection from "../components/common/intriducingSection";
import ContactInfoSec from "../components/landingPage/contactInfoSection";
import FutureLivingSection from "../components/landingPage/futureLivingSection";
import GetInTouch from "../components/landingPage/getInTouchSection";
import InteriorSection from "../components/landingPage/leadingInteriorSection";
import OurProjects from "../components/landingPage/ourProjectsSection";
import PanoramicView from "../components/landingPage/panoramicViewSection";
import WhatWeDo from "../components/landingPage/whatWeDoSection";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Arcustyle: Reimagine Your Spaces with Visionary Designs</title>
        <meta
          name="description"
          content="Elevate your spaces with Arcustyle, a Chennai-based design firm that reimagines spaces through bespoke interior design, architecture, and 3D visualization services. Explore our creative dream spaces, luxury interiors, and modern living environments. Transform the ordinary into the extraordinary with our visionary designs."
        />
        <meta
          name="keywords"
          content="Interior Design, Architecture, Bespoke Design, Dream Spaces, Luxury Living, Modern Living, Architectural Services, Creative Solutions, Innovative Design, Chennai, Arcustyle"
        />
      </Helmet>

      <Header />
      <InteriorSection />
      <WhatWeDo title="What We Do?" />
      <FutureLivingSection />
      <PanoramicView />
      <OurProjects />
      <ContactInfoSec />
      <Fade top>
        <div className="mt-5">
          <GetInTouch />
        </div>
      </Fade>

      <Fade Right>
        <IntroducingSection />
      </Fade>
      <Footer />
    </div>
  );
};

export default Home;
