import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import footerLogo from "../../../assets/footer-logo.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Instagram from "../../../assets/instagram.png";
import LinkedIn from "../../../assets/linkedin.png";
import Facebook from "../../../assets/facebook (2).png";
import { Container } from "react-bootstrap";
import { useState } from "react";

const Footer = () => {
  const navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleRedirect = (link) => {
    navigate(link);
  };

  return (
    <>
      <hr />

      <Container>
        <div className="footer-bar">
          <div className="logo">
            <Link to="/">
              <img src={footerLogo} alt="logo" />
            </Link>
          </div>

          <div className="links">
            <div className={isActive("/") ? "active home" : "home"}>
              <Link to="/">Home</Link>
            </div>
            <div className={isActive("/about") ? "active about" : "about"}>
              <Link to="/about">About Us</Link>
            </div>

            <div className="services">
              <Link onClick={() => handleDropdown()}>
                Services <ExpandMoreIcon />
              </Link>

              {isDropdownVisible && (
                <ul className="footer_menu">
                  <li onClick={() => handleRedirect("/architecture&interior")}>
                    Architecture & Interior Design
                  </li>

                  <li onClick={() => handleRedirect("/antique-woodwork")}>
                    Antique Woodwork
                  </li>

                  <li onClick={() => handleRedirect("/mep")}>MEP</li>

                  <li onClick={() => handleRedirect("/3d-visualization")}>
                    3D Visualization
                  </li>
                </ul>
              )}
            </div>

            <div
              className={isActive("/projects") ? "active projects" : "projects"}
            >
              <Link to="/projects">Projects</Link>
            </div>
            <div
              className={isActive("/contact") ? "active contact" : "contact"}
            >
              <Link to="/contact">Contact Us</Link>
            </div>
          </div>
        </div>
      </Container>

      <hr />

      <Container>
        <div className="last-section">
          <div className="copyright-content">
            © 2023 Arcustyle. All rights reserved.
          </div>
          <div className="social-network-icon">
            <a href="https://www.instagram.com/arcustyle/">
              <img src={Instagram} alt="instagram-img" />
            </a>
            <a href="https://www.linkedin.com/company/96633840/admin/notifications/all/">
              <img src={LinkedIn} alt="linkedin-img" />
            </a>
            <a href="https://www.facebook.com/Arcustyle">
              <img src={Facebook} alt="facebook-img" />
            </a>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Footer;
