import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import dashImage from "../../assets/Nav → List → Item → Link (1).png";
import MEP from "../../assets/MECHANICAL, ELECTRICAL & PLUMBING (MEP)/MECHANICAL, ELECTRICAL & PLUMBING (MEP).png";
import VendorSourcing from "../../assets/MECHANICAL, ELECTRICAL & PLUMBING (MEP)/Vendor Sourcing.png";
import SkilledLabor from "../../assets/MECHANICAL, ELECTRICAL & PLUMBING (MEP)/Skilled Labor.png";
import Installation from "../../assets/MECHANICAL, ELECTRICAL & PLUMBING (MEP)/Installation.png";
import QualityAssurance from "../../assets/MECHANICAL, ELECTRICAL & PLUMBING (MEP)/Quality Assurance.png";
import Efficiency from "../../assets/MECHANICAL, ELECTRICAL & PLUMBING (MEP)/Efficiency.png";
import Support from "../../assets/MECHANICAL, ELECTRICAL & PLUMBING (MEP)/Support.png";
import { Fade } from "react-reveal";

const MEPComponent = () => {
  return (
    <>
      <Container>
        <Fade top>
          <section>
            <div className="top mt-3">
              <img src={dashImage} alt="dashImage" />
              <div>Services We Do</div>
              <img src={dashImage} alt="dashImage" />
            </div>

            <h1 className="section_heading">
              MECHANICAL, ELECTRICAL & PLUMBING{" "}
              <span className="green_text_heading">(MEP)</span>
            </h1>
          </section>
        </Fade>

        <Fade left>
          <Row className="services_row align-items-center">
            <Col md={6} className="services_row-img_wrapper mobile-visible">
              <img src={MEP} className="services_row-img" alt="Architecture" />
            </Col>

            <Col md={6} className="services_row-texts-and-icons">
              <div className="services_row-texts">
                <div className="services_row-text-1">
                  <span>Empowering </span> Environments with MEP Excellence
                </div>

                <div className="services_row-text-2">
                  Innovating
                  <br />
                  Infrastructure for Tomorrow's Needs
                </div>
              </div>

              <div className="services_row-icons mb-5">
                <div className="icons">
                  <img src={VendorSourcing} alt="icon" />
                  <p className="icon_heading">Conceptual Design</p>
                  <p className="underText">
                    Craft innovative interior concepts that resonate with your
                    space
                  </p>
                </div>

                <div className="icons">
                  <img src={SkilledLabor} alt="icon" />
                  <p className="icon_heading">Space Planning</p>
                  <p className="underText">
                    Optimize layouts for maximum functionality and aesthetics
                  </p>
                </div>

                <div className="icons">
                  <img src={Installation} alt="icon" />
                  <p className="icon_heading">Material Selection</p>
                  <p className="underText">
                    Curate quality materials that align with your vision and
                    budget
                  </p>
                </div>

                <div className="icons">
                  <img src={QualityAssurance} alt="icon" />
                  <p className="icon_heading">Furniture & Furnishings</p>
                  <p className="underText">
                    Source and place stylish, ergonomic furniture and decor
                  </p>
                </div>

                <div className="icons">
                  <img src={Efficiency} alt="icon" />
                  <p className="icon_heading">Lighting & Color</p>
                  <p className="underText">
                    Create ambiance with strategic lighting and color choices
                  </p>
                </div>

                <div className="icons">
                  <img src={Support} alt="icon" />
                  <p className="icon_heading">Project Management</p>
                  <p className="underText">
                    Coordinate every detail to ensure timely and on-budget
                    delivery
                  </p>
                </div>
              </div>
            </Col>

            <Col md={6} className="services_row-img_wrapper mobile-hidden">
              <img src={MEP} className="services_row-img" alt="Architecture" />
            </Col>
          </Row>
        </Fade>
      </Container>

      <hr />
      <Fade bottom>
        <h1 className="section_heading mt-5">
          Elevate your project with top-notch{" "}
          <span className="green_text_heading">MEP solutions.</span>
        </h1>
      </Fade>

      <hr />
    </>
  );
};

export default MEPComponent;
