import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import dashImage from "../../assets/Nav → List → Item → Link (1).png";
import ThreeD from "../../assets/3D VISUALIZATION/3D VISUALIZATION.png";
import VisualExcellence from "../../assets/3D VISUALIZATION/Visual Excellence.png";
import ARImage from "../../assets/3D VISUALIZATION/Augmented Reality (AR).png";
import VRImage from "../../assets/3D VISUALIZATION/Virtual Reality (VR).png";
import XRImage from "../../assets/3D VISUALIZATION/Extended Reality (XR).png";
import ArchitecturalInsight from "../../assets/3D VISUALIZATION/Architectural Insight.png";
import PanoramicImg from "../../assets/3D VISUALIZATION/Panoramic Image.png";
import Three60 from "../../assets/3D VISUALIZATION/360.png";
import ZoomPan from "../../assets/3D VISUALIZATION/zoom and pan.png";
import Cross from "../../assets/3D VISUALIZATION/cross.png";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { Fade } from "react-reveal";

const ThreeDVisualizationComponent = () => {
  return (
    <>
      <Container>
        <Fade top>
          <section>
            <div className="top mt-3">
              <img src={dashImage} alt="dashImage" />
              <div>Services We Do</div>
              <img src={dashImage} alt="dashImage" />
            </div>

            <h1 className="section_heading">
              3D <span className="green_text_heading">Visualization</span>
            </h1>
          </section>
        </Fade>

        <Fade left>
          <Row className="services_row align-items-center">
            <Col md={6} className="services_row-img_wrapper mobile-visible">
              <img
                src={ThreeD}
                className="services_row-img"
                alt="Architecture"
              />
            </Col>

            <Col md={6} className="services_row-texts-and-icons">
              <div className="services_row-texts">
                <div className="services_row-text-1">
                  <span>Immersive </span> Architectural Visions Come To Life
                </div>

                <div className="services_row-text-2">
                  Exploring
                  <br />
                  Spaces in a new reality
                </div>
              </div>

              <div className="services_row-icons mb-5">
                <div className="icons">
                  <img src={VisualExcellence} alt="icon" />
                  <p className="icon_heading">Visual Excellence</p>
                  <p className="underText">
                    Create stunning 3D architectural renders and immersive
                    experiences.
                  </p>
                </div>

                <div className="icons">
                  <img src={ARImage} alt="icon" />
                  <p className="icon_heading">
                    Augmented Reality{" "}
                    <span className="green_text_heading">(AR)</span>
                  </p>
                  <p className="underText">
                    Enhance real-world spaces with digital overlays
                  </p>
                </div>

                <div className="icons">
                  <img src={VRImage} alt="icon" />
                  <p className="icon_heading">
                    Virtual Reality{" "}
                    <span className="green_text_heading">(VR)</span>
                  </p>
                  <p className="underText">
                    Immerse clients in interactive virtual environments
                  </p>
                </div>

                <div className="icons">
                  <img src={XRImage} alt="icon" />
                  <p className="icon_heading">
                    Extended Reality{" "}
                    <span className="green_text_heading">(XR)</span>
                  </p>
                  <p className="underText">
                    Blend AR and VR for comprehensive experiences
                  </p>
                </div>

                <div className="icons">
                  <img src={ArchitecturalInsight} alt="icon" />
                  <p className="icon_heading">Architectural Insight</p>
                  <p className="underText">
                    Provide a deep understanding of design concepts
                  </p>
                </div>
              </div>
            </Col>

            <Col md={6} className="services_row-img_wrapper mobile-hidden">
              <img
                src={ThreeD}
                className="services_row-img"
                alt="Architecture"
              />
            </Col>
          </Row>
        </Fade>
      </Container>

      <hr />

      <div>
        <Fade top>
          <div className="top">
            <img src={dashImage} alt="dashImage" />
            <div>Services We Do</div>
            <img src={dashImage} alt="dashImage" />
          </div>
          <h1 className="section_heading mt-1 mb-5">Panoramic View 360°</h1>
        </Fade>

        <Fade bottom>
          <div className="container mt-4 mb-4 d-flex justify-content-center">
            <ReactPhotoSphereViewer
              src={PanoramicImg}
              height={"80vh"}
              width={"100%"}
            />
          </div>
        </Fade>
      </div>

      <hr />

      <div className="three_sixty-texts-and-icons">
        <Row>
          <Col md={12} className="mt-5">
            <div className="three_sixty-icons mb-5">
              <Fade left>
                <div className="icons">
                  <img src={Three60} alt="icon" />
                  <p className="icon_heading">360-Degree Rotation</p>
                  <p className="underText">
                    Explore 3D models from every angle with a seamless
                    360-degree rotation for a comprehensive view.
                  </p>
                </div>
              </Fade>

              <Fade top>
                <div className="icons">
                  <img src={ZoomPan} alt="icon" />
                  <p className="icon_heading">Zoom and Pan Functionality</p>
                  <p className="underText">
                    Zoom in, pan out – effortlessly navigate through 3D scenes
                    for detailed inspection or a panoramic view.
                  </p>
                </div>
              </Fade>

              <Fade right>
                <div className="icons">
                  <img src={Cross} alt="icon" />
                  <p className="icon_heading">Cross-Platform Compatibility</p>
                  <p className="underText">
                    Our 360 viewer ensures a consistent, responsive experience
                    across desktops, laptops, tablets, and smartphones.
                  </p>
                </div>
              </Fade>
            </div>
          </Col>
        </Row>
      </div>

      <hr />
    </>
  );
};

export default ThreeDVisualizationComponent;
