import "./style.css";
import phoneIcon from "../../../assets/phone-icon.png";
import mailIcon from "../../../assets/mail-icon.png";
import visitUsIcon from "../../../assets/visit-us-icon.png";
import visitBetweenIcon from "../../../assets/visit-between-icon.png";
import BottomCorner from "../../common/bottomCorner";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";

const ContactInfoSec = () => {
  return (
    <Container>
      <div className="contact-info">
        <Fade bottom>
          <Row className="contact-info-icons">
            <Col className="phone-icon contact-info-icon">
              <div className="icon-bottom">
                <img src={phoneIcon} alt="phone-icon" />
                <div className="phone icon-title">Phone Number</div>
                <div className="phone-number">+91-8056223012</div>
              </div>
            </Col>

            <Col className="email-icon contact-info-icon">
              <div className="icon-bottom">
                <img src={mailIcon} alt="mail-icon" />
                <div className="email icon-title">Email Address</div>
                <div className="email-address">hello@arcustyle.com</div>
              </div>
            </Col>

            <Col className="visit-icon contact-info-icon">
              <div className="icon-bottom">
                <img src={visitUsIcon} alt="visit-us-icon" />
                <div className="visit icon-title">Visit us at</div>
                <div className="address">
                  1st Floor, No.4/101C, Sri Ram Nagar, Puthagaram, Kolathur,
                  Chennai, Tamil Nadu, 600099
                </div>
              </div>
            </Col>

            <Col className="visit-time-icon contact-info-icon">
              <div className="icon-bottom">
                <img src={visitBetweenIcon} alt="visit-time-icon" />
                <div className="visit-time icon-title">Visit Between</div>
                <div className="phone-number ">
                  Mon - Sat : 9.00 AM - 6.00 PM
                  <br />
                  Sunday : Closed
                </div>
              </div>
            </Col>
          </Row>
        </Fade>

        {/* <div className="bottom_corner">
          <BottomCorner
            bottomCornerHeight="16.8rem"
            bottomCornerleft="-2rem"
            bottomCornerTop="-15.5rem"
            greenRectLeft="17.65rem"
            greenRectTop="-15.75rem"
          />
        </div> */}
      </div>
    </Container>
  );
};

export default ContactInfoSec;
