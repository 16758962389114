import "./style.css";
import projectBtn from "../../../assets/Section → Link.png";
import projectImage1 from "../../../assets/Mask group.png";
import projectImage2 from "../../../assets/white-bedroom-interior-mockup 1.png";
import projectImage3 from "../../../assets/living-room-interior-wall-mockup-warm-tones-with-leather-sofa-which-is-kitchen-3d-rendering 1.png";
import projectImage4 from "../../../assets/3d-rendering-loft-luxury-living-room-with-yellow-armchair-with-bookshelf 1.png";
import projectImage5 from "../../../assets/contemporary-office 1.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { PhotoView } from "react-photo-view";
import { Fade, Zoom } from "react-reveal";
import ViewProjectsBtn from "../../common/viewProjectsBtn";

const OurProjects = () => {
  return (
    <Container>
      <div className="our-projects">
        <Fade top>
          <div className="our-projects-top mb-5">
            <div className="our-projects-heading">Our Featured Projects</div>
            <div className="view-projects-btn_container">
              <ViewProjectsBtn link="/projects" text="View Projects" />
            </div>
          </div>
        </Fade>

        <div className="our-projects-image">
          <Container>
            <Zoom>
              <Row>
                <Col lg={8}>
                  <PhotoView src={projectImage1}>
                    <img
                      src={projectImage1}
                      className="w-100 shadow-1-strong rounded mb-4"
                      alt="img-1"
                    />
                  </PhotoView>
                </Col>

                <Col lg={4}>
                  <PhotoView src={projectImage2}>
                    <img
                      src={projectImage2}
                      className="w-100 shadow-1-strong rounded mb-4"
                      alt="img-2"
                    />
                  </PhotoView>

                  <PhotoView src={projectImage3}>
                    <img
                      src={projectImage3}
                      className="w-100 shadow-1-strong rounded mb-4"
                      alt="img-3"
                    />
                  </PhotoView>
                </Col>
              </Row>
              <Row>
                <Col lg={7}>
                  <PhotoView src={projectImage4}>
                    <img
                      src={projectImage4}
                      className="w-100 shadow-1-strong rounded mb-4"
                      alt="img-4"
                    />
                  </PhotoView>
                </Col>
                <Col lg={5}>
                  <PhotoView src={projectImage5}>
                    <img
                      src={projectImage5}
                      className="w-100 shadow-1-strong rounded mb-4"
                      alt="img-5"
                    />
                  </PhotoView>
                </Col>
              </Row>
            </Zoom>
          </Container>
        </div>
      </div>
    </Container>
  );
};
export default OurProjects;
