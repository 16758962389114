import { Col, Container, Row } from "react-bootstrap";
import "./style.css";

const DesignMasterySec = () => {
  return (
    <Container>
      <Row className="design-mastery-sec">
        <Col md={8} className="design-mastery-left-sec">
          <div className="design-mastery-text-1">
            <span>About</span> Arcustyle
          </div>
          <div className="design-mastery-text-2">
            Design Mastery,
            <br /> Execution Excellence
          </div>
          <div className="design-mastery-text-3">
            At Arcustyle, our design and execution team stands at the forefront
            of innovation, seamlessly merging artistic vision with unparalleled
            expertise. With a commitment to precision and a passion for
            perfection, we transform concepts into reality. From
            conceptualization to the final brushstroke, our team ensures that
            every detail is meticulously crafted, ensuring an extraordinary
            outcome that exceeds expectations. Trust Arcustyle to bring your
            dreams to life with design mastery and execution excellence.
          </div>
          <div className="design-mastery-text-4">
            <div className="building-img"></div>
            <div className="design-mastery-text-4-office-name">
              Arcustyle’s Office
            </div>
            <div className="design-mastery-text-4-office-addr">
              1st Floor, No.4/101C, Sri Ram Nagar, Puthagaram, Kolathur,
              Chennai, Tamil Nadu, 600099
            </div>
          </div>
        </Col>
        <Col md={4} className="design-mastery-right-sec"></Col>
      </Row>
    </Container>
  );
};

export default DesignMasterySec;
