import "./style.css";
import dashImage from "../../../assets/Nav → List → Item → Link (1).png";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import ViewProjectsBtn from "../viewProjectsBtn";

const IntroducingSection = () => {
  return (
    <Container className="intruducing-section">
      <div className="top">
        <img src={dashImage} alt="dashImage" />
        <div>Introducing Arcustyle</div>
        <img src={dashImage} alt="dashImage" />
      </div>

      <div className="bottom">Where Your Dream Interiors Come to Life</div>
      <div className="bottom-most">
        Share us your idea, we will design the rest to bring it to live.
      </div>
      <div className="get-in-touch-btn">
        <ViewProjectsBtn link="/contact" text="Get In Touch" />
      </div>
    </Container>
  );
};
export default IntroducingSection;
