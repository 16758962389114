import React from "react";
import ServicesBoilerPlate from "../../components/common/Services/ServicesBoilerPlate";
import ThreeDVisualizationBanner from "../../assets/3D VISUALIZATION/3DVisualizationBanner.png";
import ThreeDVisualizationComponent from "../../components/services/ThreeDVisualizationComponent";
import { Helmet } from "react-helmet";

const ThreeDVisualization = () => {
  return (
    <>
      <Helmet>
        <title>
          3D Visualization Services: Bring Architectural Visions to Life with
          Arcustyle
        </title>
        <meta
          name="description"
          content="Immerse in architectural visions with Arcustyle's 3D Visualization Services. From visual excellence to extended reality experiences, explore spaces in a new reality, ensuring alignment between your vision and the final project.
"
        />
      </Helmet>
      <ServicesBoilerPlate
        bannerImage={ThreeDVisualizationBanner}
        componentToRender="ThreeDVisualization"
        component={<ThreeDVisualizationComponent />}
      />
    </>
  );
};

export default ThreeDVisualization;
