import React, { useEffect, useState } from "react";
import "./style.css";

const Preloader = () => {
  const [num, setNum] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (num < 100) {
        setNum((prevNum) => prevNum + 1);
      } else {
        clearInterval(intervalId);
      }
    }, 39);

    return () => clearInterval(intervalId);
  }, [num]);

  return (
    <div className="pre_loader">
      <div className="loader_wrapper">
        <span class="loader"></span>
      </div>
      <p className="loading_percentage">{num}%</p>
    </div>
  );
};

export default Preloader;
