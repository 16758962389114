import "./style.css";
import timeIcon from "../../../assets/time-icon.png";
import moneyIcon from "../../../assets/money-icon.png";
import focusIcon from "../../../assets/focus-icon.png";
import teamIcon from "../../../assets/team-icon.png";
import deliveryIcon from "../../../assets/delivery-icon.png";
import { Col, Container, Row } from "react-bootstrap";
import futerLivingSecImage from "../../../assets/future-living-sec-img.png";
import { Fade } from "react-reveal";

const FutureLivingSection = () => {
  return (
    <Container>
      <Fade bottom>
        <Row className="future-living justify-content-center mt-5">
          <Col md={6} className="future-living-img_wrapper">
            <img
              className="future-living-img"
              src={futerLivingSecImage}
              alt="img"
            />
          </Col>

          <Col md={6} className="future-living-texts-and-icons">
            <div className="future-living-texts">
              <div className="future-living-text-1">
                <span>Why</span> The Arcustyle ?
              </div>

              <div className="future-living-text-2">
                Transcending <br />
                Interiors: Future Living Redefined
              </div>

              <div className="future-living-text-3">
                At Arcustyle, we understand that choosing the right
                architectural partner is a crucial decision for your project.
                Here are compelling reasons why you should choose us:
              </div>
            </div>

            <div className="future-living-icons">
              <div className="future-living-icons-firstline">
                <div className="time-icon icons">
                  <img src={timeIcon} alt="icon" />
                  <p>Swift Turnaround Time</p>
                </div>

                <div className="money-icon icons">
                  <img src={moneyIcon} alt="icon" />
                  <p>Value for Money</p>
                </div>

                <div className="focus-icon icons">
                  <img src={focusIcon} alt="icon" />
                  <p>Customer Focus</p>
                </div>
              </div>

              <div className="future-living-icons-secondline">
                <div className="team-icon icons">
                  <img src={teamIcon} alt="icon" />
                  <p>Cross-Disciplinary Team</p>
                </div>
                <div className="delivery-icon icons">
                  <img src={deliveryIcon} alt="icon" />
                  <p>On-time Delivery</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
};

export default FutureLivingSection;
