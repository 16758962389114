import { useEffect } from "react";
import BannerSection from "../components/aboutPage/bannerSection";
import Footer from "../components/common/footer";
import CustomNavbar from "../components/common/navbar";
import ContactInfoSec from "../components/landingPage/contactInfoSection";
import GetInTouch from "../components/landingPage/getInTouchSection";
import ContactBanner from "../assets/Contact US Banner.png";
import { Fade, Zoom } from "react-reveal";
import { Helmet } from "react-helmet";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          Contact Arcustyle - Reach Out for Architectural Excellence and Design
          Solutions
        </title>
        <meta
          name="description"
          content="Connect with Arcustyle, your partner in architectural excellence. Contact us for bespoke design solutions, innovative architecture, and personalized interior designs. We are here to transform your visions into reality. Reach out today."
        />
      </Helmet>
      <CustomNavbar />
      <BannerSection bannerImage={ContactBanner} />
      <Fade bottom>
        <GetInTouch />
      </Fade>
      <hr />
      <div className="contact_us_page">
        <ContactInfoSec />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
