import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./style.css";
import couchImg from "../../../assets/Living room interior set with couch.png";

const GetInTouch = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();
  };
  return (
    <Container className="get-in-touch-section">
      <Row>
        <Col lg={6} className="get-in-touch">
          <h2 className="get-in-touch-heading">Get In Touch</h2>
          <Form onSubmit={handleSubmit} className="get-in-touch-form">
            <Row>
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Your Name" />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formMobileNumber">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Mobile Number"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formCity">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your City Name"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formMail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter Your Email" />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formPincode">
                  <Form.Label>PinCode</Form.Label>
                  <Form.Control type="text" placeholder="Enter Your ZipCode" />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formProjectSize">
                  <Form.Label>Project Size (SQ Feet)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Project Size"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formProjectLocation">
                  <Form.Label>Project Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Project Location"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formBudgetIdea">
                  <Form.Label>Budget Idea</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Project Budget Ideas"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="formRequirements">
              <Form.Label>Your Requirements</Form.Label>
              <Form.Control as="textarea" rows={4} />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="mt-3 text-center get-in-touch_btn"
            >
              Send
            </Button>
          </Form>
        </Col>

        <Col lg={6} className="couch-img_wrapper">
          <img className="couch-img" src={couchImg} alt="couch render" />
        </Col>
      </Row>
    </Container>
  );
};

export default GetInTouch;
