import "./style.css";
import dashImage from "../../../assets/Nav → List → Item → Link (1).png";
import TopCorner from "../../common/top-right-corner-design";
import image1 from "../../../assets/Architecture.png";
import image2 from "../../../assets/Interior Design.png";
import image3 from "../../../assets/MEP.png";
import image4 from "../../../assets/3D Visualization.png";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import ReactFlipCard from "reactjs-flip-card";
import frame1 from "../../../assets/what we do/Frame (1).png";
import frame2 from "../../../assets/what we do/Frame (2).png";
import frame3 from "../../../assets/what we do/Frame.png";
import frame4 from "../../../assets/what we do/Rectangle.png";
import { Link } from "react-router-dom";
import ViewProjectsBtn from "../../common/viewProjectsBtn";

const WhatWeDo = ({ title }) => {
  return (
    <Container>
      <div className="what-we-do-section">
        {/* <div className="top_corner">
          <TopCorner />
        </div> */}
        <Fade top>
          <div className="what-we-do-section-top">
            <div className="top">
              <img src={dashImage} alt="dashImage" />
              <div>Check Out</div>
              <img src={dashImage} alt="dashImage" />
            </div>

            <div className="bottom">{title}</div>
          </div>
        </Fade>

        <div className="what-we-do-section_images">
          <Fade left>
            <div className="img_wrapper">
              <ReactFlipCard
                containerStyle={{ width: "16rem", height: "35rem" }}
                frontComponent={
                  <img
                    style={{ marginTop: "0px" }}
                    className="img_front"
                    src={image1}
                    alt="architecture-img"
                  />
                }
                backComponent={
                  <div className="card_back_container">
                    <img src={frame3} alt="" />

                    <div className="card_back_info_container">
                      <div className="card_back_info">
                        <h5 className="what_we_do_card_heading">
                          Architecture & Interior Design
                        </h5>

                        <p className="what_we_do_card_para">
                          Where innovation meets luxury, elevate your spaces
                          with our seamless blend of architectural prowess and
                          personalized design.
                        </p>
                      </div>

                      <div className="view_more_button_container">
                        <ViewProjectsBtn
                          link="/architecture&interior"
                          text="View More"
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </Fade>

          <Fade top>
            <div className="img_wrapper">
              <ReactFlipCard
                containerStyle={{ width: "16rem", height: "35rem" }}
                frontComponent={
                  <img
                    style={{ marginTop: "0px" }}
                    className="img_front"
                    src={image2}
                    alt="interior-img"
                  />
                }
                backComponent={
                  <div className="card_back_container">
                    <img src={frame1} alt="" />

                    <div className="card_back_info_container">
                      <div className="card_back_info">
                        <h5 className="what_we_do_card_heading">
                          antique woodwork
                        </h5>
                        <p className="what_we_do_card_para">
                          Crafted from history, our antique woodwork brings
                          timeless charm to your spaces. Elevate with heritage
                          and let every piece tell a story.
                        </p>
                      </div>

                      <div className="view_more_button_container">
                        <ViewProjectsBtn
                          link="/antique-woodwork"
                          text="View More"
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </Fade>

          <Fade bottom>
            <div className="img_wrapper">
              <ReactFlipCard
                containerStyle={{ width: "16rem", height: "35rem" }}
                frontComponent={
                  <img
                    style={{ marginTop: "0px" }}
                    className="img_front"
                    src={image3}
                    alt="mep-img"
                  />
                }
                backComponent={
                  <div className="card_back_container">
                    <img src={frame4} alt="" />

                    <div className="card_back_info_container">
                      <div className="card_back_info">
                        <h5 className="what_we_do_card_heading">
                          Mechanical, Electrical, and Plumbing (MEP)
                        </h5>
                        <p className="what_we_do_card_para">
                          Integrate aesthetics with precision. Our MEP services
                          ensure optimal performance and sustainability,
                          seamlessly blending functionality into every project.
                        </p>
                      </div>

                      <div className="view_more_button_container">
                        <ViewProjectsBtn link="/mep" text="View More" />
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </Fade>

          <Fade right>
            <div className="img_wrapper">
              <ReactFlipCard
                containerStyle={{ width: "16rem", height: "35rem" }}
                frontComponent={
                  <img
                    style={{ marginTop: "0px" }}
                    className="img_front"
                    src={image4}
                    alt="3d-visualizer-img"
                  />
                }
                backComponent={
                  <div className="card_back_container">
                    <img src={frame2} alt="" />

                    <div className="card_back_info_container">
                      <div className="card_back_info">
                        <h5 className="what_we_do_card_heading">
                          3D Visualization
                        </h5>
                        <p className="what_we_do_card_para">
                          Experience your vision come to life with our
                          cutting-edge 3D visualization services, offering a
                          virtual journey through your design, ensuring clarity
                          and alignment before execution.
                        </p>
                      </div>

                      <div className="view_more_button_container">
                        <ViewProjectsBtn
                          link="/3d-visualization"
                          text="View More"
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </Fade>
        </div>
      </div>
    </Container>
  );
};
export default WhatWeDo;
