import "./style.css";
import dashImage from "../../../assets/Nav → List → Item → Link (1).png";
import timeIcon from "../../../assets/time-icon.png";
import moneyIcon from "../../../assets/money-icon.png";
import focusIcon from "../../../assets/focus-icon.png";
import teamIcon from "../../../assets/team-icon.png";
import deliveryIcon from "../../../assets/delivery-icon.png";
import { Container } from "react-bootstrap";
import { Fade, Zoom } from "react-reveal";

const WhyToChoose = () => {
  return (
    <Container>
      <div className="WhyToChoose-section">
        <Fade top>
          <div className="WhyToChoose-heading">
            <div className="top">
              <img src={dashImage} alt="dashImage" />
              <div>Why To</div>
              <img src={dashImage} alt="dashImage" />
            </div>

            <div className="bottom">Choose Us?</div>
            <div className="bottom-most-line">
              At Arcustyle, we understand that choosing the right architectural
              partner is a crucial decision for your project. Here are
              compelling reasons why you should choose us:
            </div>
          </div>
        </Fade>

        <Zoom>
          <div className="future-living-icons">
            <div className="future-living-icons-firstline">
              <div className="time-icon icons">
                <img src={timeIcon} alt="icon" />
                <p>Swift Turnaround Time</p>
              </div>

              <div className="money-icon icons">
                <img src={moneyIcon} alt="icon" />
                <p>Value for Money</p>
              </div>

              <div className="focus-icon icons">
                <img src={focusIcon} alt="icon" />
                <p>Customer Focus</p>
              </div>
            </div>
            <div className="future-living-icons-secondline">
              <div className="team-icon icons">
                <img src={teamIcon} alt="icon" />
                <p>Cross-Disciplinary Team</p>
              </div>
              <div className="delivery-icon icons">
                <img src={deliveryIcon} alt="icon" />
                <p>On-time Delivery</p>
              </div>
            </div>
          </div>
        </Zoom>

        <Fade bottom>
          <div className="WhyToChoose-section-last-content">
            Choosing Arcustyle means choosing a partner dedicated to excellence,
            efficiency, innovation, and customer satisfaction. We are committed
            to turning your architectural dreams into reality, all while
            providing exceptional value for your investment. Join us on a
            journey where your project's success is our primary focus, and
            together, we'll create spaces that inspire and endure.
          </div>
        </Fade>
      </div>
    </Container>
  );
};

export default WhyToChoose;
