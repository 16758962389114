import BannerSection from "../components/aboutPage/bannerSection";
import dashImage from "../assets/Nav → List → Item → Link (1).png";
import img1 from "../assets/design-1.png";
import img2 from "../assets/design-2.png";
import img3 from "../assets/design-3.png";
import img4 from "../assets/design-4.png";
import img5 from "../assets/design-5.png";
import img6 from "../assets/design-6.png";
import img7 from "../assets/design-7.png";
import img8 from "../assets/design-8.png";
import img9 from "../assets/design-9.png";

import OurDesigns from "../components/projectPage/ourDesigns";
import GetInTouch from "../components/landingPage/getInTouchSection";
import Footer from "../components/common/footer";
import commercialDsign1 from "../assets/Frame 12.png";
import commercialDsign2 from "../assets/Frame 15.png";

import commercialDsign3 from "../assets/Frame 9.png";
import architecturalDesign1 from "../assets/Frame 13.png";
import architecturalDesign2 from "../assets/Frame 14.png";
import { useEffect } from "react";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import AntiqueWoodwork1 from "../assets/antique woodwork/1.png";
import AntiqueWoodwork2 from "../assets/antique woodwork/2.png";
import AntiqueWoodwork3 from "../assets/antique woodwork/3.png";

const Projects = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const residentialDesigns = [
    { img: img1, altText: "frame-16", project: "The Opulenz" },
    { img: img2, altText: "frame-16", project: "Modernität" },
    { img: img3, altText: "frame-16", project: "Sereno" },
    { img: img4, altText: "frame-16", project: "Sanskriti" },
    { img: img5, altText: "frame-16", project: "Harmonie" },
    { img: img6, altText: "frame-16", project: "Le Charmant" },
    { img: img7, altText: "frame-16", project: "The Innenraum" },
    { img: img8, altText: "frame-16", project: "The Rasoi" },
    { img: img9, altText: "frame-16", project: "The Eleganza" },
  ];

  const commercialDesigns = [
    { img: commercialDsign1, altText: "frame-16", project: "Showroom" },
    { img: commercialDsign2, altText: "frame-16", project: "Cafe" },
    { img: commercialDsign3, altText: "frame-16", project: "Office" },
  ];

  const architecturalDesigns = [
    { img: architecturalDesign1, altText: "frame-16", project: "Casa Convida" },
    { img: architecturalDesign2, altText: "frame-16", project: "Dún Solais" },
  ];

  const antiqueWoodworkDesigns = [
    {
      img: AntiqueWoodwork1,
      altText: "frame-16",
      project: "Exquisite Antique Chettinad Teak Wood Door",
    },
    {
      img: AntiqueWoodwork2,
      altText: "frame-16",
      project: "Natural Hand Carved Antique Wooden Pillar",
    },
    {
      img: AntiqueWoodwork3,
      altText: "frame-16",
      project: "Antique Handcrafted Carving Design",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Our Latest Projects | Residential and Commercial Design Portfolio |
          Arcustyle
        </title>
        <meta
          name="description"
          content="Explore Arcustyle's latest projects showcasing excellence in residential and commercial design. Immerse yourself in our diverse portfolio, where innovation and creativity converge to redefine living and workspaces. Discover the art of design mastery with Arcustyle."
        />
      </Helmet>
      <BannerSection />
      <Fade top>
        <div className="project-section">
          <div className="top">
            <img src={dashImage} alt="dashImage" />
            <div>Check Out our works</div>
            <img src={dashImage} alt="dashImage" />
          </div>

          <div className="bottom">Our Latest Projects</div>
        </div>
      </Fade>

      <OurDesigns title="Residential" designs={residentialDesigns} />

      <OurDesigns title="commercial" designs={commercialDesigns} />

      <OurDesigns title="antique woodwork" designs={antiqueWoodworkDesigns} />

      <OurDesigns title="architectural" designs={architecturalDesigns} />

      <Fade right>
        <div className="mt-5">
          <GetInTouch />
        </div>
      </Fade>
      <Footer />
    </>
  );
};

export default Projects;
