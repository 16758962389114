import "./style.css";
import { Link } from "react-router-dom";

const ViewProjectsBtn = (props) => {
  return (
    <div>
      <button className="view-project-btn">
        <Link to={props.link}>{props.text}</Link>
      </button>
    </div>
  );
};
export default ViewProjectsBtn;
