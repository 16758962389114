import React from "react";
import {
  Col,
  Container,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import "./style.css";
import CasaConvida from "../../assets/Architecture/Casa Convida v2.jpg";
import dashImage from "../../assets/Nav → List → Item → Link (1).png";
import ArchitectureImage from "../../assets/Architecture/Architecture.png";
import InteriorImage from "../../assets/Interior Design/Interior Design.png";
import Analysis from "../../assets/Architecture/Analysis.png";
import Design from "../../assets/Architecture/Design.png";
import WorkingDrawings from "../../assets/Architecture/Working Drawings.png";
import DetailedDrawings from "../../assets/Architecture/Detailed Drawings.png";
import ThreeD from "../../assets/Architecture/3-D Representations.png";
import Tendering from "../../assets/Architecture/Tendering.png";
import SiteMonitoring from "../../assets/Architecture/Site Monitoring.png";
import TeamCoordination from "../../assets/Architecture/Team Coordination.png";
import BillOfQuantities from "../../assets/Architecture/Bill of Quantities.png";
import MaterialSelection from "../../assets/Interior Design/Material Selection.png";
import FurnitureFurnishings from "../../assets/Interior Design/Furniture & Furnishings.png";
import LightingColor from "../../assets/Interior Design/Lighting & Color.png";
import InteriorDesignImage1 from "../../assets/design-1.png";
import InteriorDesignImage2 from "../../assets/design-7.png";
import InteriorDesignImage3 from "../../assets/design-3.png";
import InteriorDesignImage4 from "../../assets/design-4.png";
import InteriorDesignImage5 from "../../assets/design-2.png";
import InteriorDesignImage6 from "../../assets/design-9.png";
import ContactInfoSec from "../landingPage/contactInfoSection";
import ProjectShowcase from "../common/Services/ProjectShowcase";
import { Fade } from "react-reveal";

const ArchitectureInteriorComponent = (props) => {
  const InteriorDesignProjects = [
    {
      img: InteriorDesignImage1,
      project: "The Opulenz",
    },
    {
      img: InteriorDesignImage2,
      project: "The Innenraum",
    },
    {
      img: InteriorDesignImage3,
      project: "Sereno",
    },
    {
      img: InteriorDesignImage4,
      project: "Sanskriti",
    },
    {
      img: InteriorDesignImage5,
      project: "Modernität",
    },
    {
      img: InteriorDesignImage6,
      project: "The Eleganza",
    },
  ];

  return (
    <>
      <Fade top>
        <section>
          <Container>
            <div className="top mt-3">
              <img src={dashImage} alt="dashImage" />
              <div>Services We Do</div>
              <img src={dashImage} alt="dashImage" />
            </div>
          </Container>

          <div className="choiceSelector">
            <ToggleButtonGroup
              type="radio"
              name="options"
              className="choiceSelector_wrapper"
              defaultValue={1}
            >
              <ToggleButton
                className="choiceSelector_btn"
                id="tbg-radio-1"
                value={1}
                onClick={() => props.setSelectedServices("architecture")}
              >
                Architecture
              </ToggleButton>
              <ToggleButton
                className="choiceSelector_btn"
                id="tbg-radio-2"
                value={2}
                onClick={() => props.setSelectedServices("interior")}
              >
                Interior Design
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </section>
      </Fade>

      {props.selectedServices === "architecture" ? (
        <Container>
          <Fade left>
            <Row className="services_row align-items-center">
              <Col md={6} className="services_row-img_wrapper mobile-visible">
                <img
                  src={ArchitectureImage}
                  className="services_row-img"
                  alt="Architecture"
                />
              </Col>

              <Col md={6} className="services_row-texts-and-icons">
                <div className="services_row-texts">
                  <div className="services_row-text-1">
                    <span>Shopping</span> The Arcustyle ?
                  </div>

                  <div className="services_row-text-2">
                    CRAFTING THE FUTURE OF <br />
                    DESIGN WITH PRECISION AND VISION
                  </div>
                </div>

                <div className="services_row-icons mb-5">
                  <div className="icons">
                    <img src={Analysis} alt="icon" />
                    <p className="icon_heading">Analysis</p>
                    <p className="underText">
                      Understand client needs and site conditions
                    </p>
                  </div>

                  <div className="icons">
                    <img src={Design} alt="icon" />
                    <p className="icon_heading">Design</p>
                    <p className="underText">
                      Develop concept, comply with codes, and get client
                      feedback
                    </p>
                  </div>

                  <div className="icons">
                    <img src={WorkingDrawings} alt="icon" />
                    <p className="icon_heading">Working Drawings</p>
                    <p className="underText">
                      Create comprehensive architectural plans
                    </p>
                  </div>

                  <div className="icons">
                    <img src={DetailedDrawings} alt="icon" />
                    <p className="icon_heading">Detailed Drawings</p>
                    <p className="underText">
                      Cover partitions, flooring, lighting, and more...
                    </p>
                  </div>

                  <div className="icons">
                    <img src={ThreeD} alt="icon" />
                    <p className="icon_heading">3-D Representations</p>
                    <p className="underText">
                      Visualize design for client approval
                    </p>
                  </div>

                  <div className="icons">
                    <img src={Tendering} alt="icon" />
                    <p className="icon_heading">Tendering</p>
                    <p className="underText">
                      Assist with contractor selection
                    </p>
                  </div>

                  <div className="icons">
                    <img src={SiteMonitoring} alt="icon" />
                    <p className="icon_heading">Site Monitoring</p>
                    <p className="underText">
                      Ensure quality during construction
                    </p>
                  </div>

                  <div className="icons">
                    <img src={TeamCoordination} alt="icon" />
                    <p className="icon_heading">Team Coordination</p>
                    <p className="underText"> Work with consultants</p>
                  </div>

                  <div className="icons">
                    <img src={BillOfQuantities} alt="icon" />
                    <p className="icon_heading">Bill of Quantities</p>
                    <p className="underText"> List materials and quantities</p>
                  </div>
                </div>
              </Col>

              <Col md={6} className="services_row-img_wrapper mobile-hidden">
                <img
                  src={ArchitectureImage}
                  className="services_row-img"
                  alt="Architecture"
                />
              </Col>
            </Row>
          </Fade>

          <Fade right>
            <div className="architecture_img_wrapper">
              <img
                src={CasaConvida}
                className="architecture_img"
                alt="CasaConvida"
              />
            </div>
          </Fade>

          <div className="services_page">
            <ContactInfoSec />
          </div>
        </Container>
      ) : (
        <>
          <Container>
            <Fade left>
              <Row className="services_row align-items-center">
                <Col md={6} className="services_row-img_wrapper mobile-visible">
                  <img
                    src={InteriorImage}
                    className="services_row-img"
                    alt="Architecture"
                  />
                </Col>

                <Col md={6} className="services_row-texts-and-icons">
                  <div className="services_row-texts">
                    <div className="services_row-text-1">
                      <span>Revolutionize </span> Your Interiors
                    </div>

                    <div className="services_row-text-2">
                      CRAFTING SPACES <br />
                      FOR TOMORROW'S LIVING
                    </div>
                  </div>

                  <div className="services_row-icons mb-5">
                    <div className="icons">
                      <img src={DetailedDrawings} alt="icon" />
                      <p className="icon_heading">Conceptual Design</p>
                      <p className="underText">
                        Craft innovative interior concepts that resonate with
                        your space
                      </p>
                    </div>

                    <div className="icons">
                      <img src={SiteMonitoring} alt="icon" />
                      <p className="icon_heading">Space Planning</p>
                      <p className="underText">
                        Optimize layouts for maximum functionality and
                        aesthetics
                      </p>
                    </div>

                    <div className="icons">
                      <img src={MaterialSelection} alt="icon" />
                      <p className="icon_heading">Material Selection</p>
                      <p className="underText">
                        Curate quality materials that align with your vision and
                        budget
                      </p>
                    </div>

                    <div className="icons">
                      <img src={FurnitureFurnishings} alt="icon" />
                      <p className="icon_heading">Furniture & Furnishings</p>
                      <p className="underText">
                        Source and place stylish, ergonomic furniture and decor
                      </p>
                    </div>

                    <div className="icons">
                      <img src={LightingColor} alt="icon" />
                      <p className="icon_heading">Lighting & Color</p>
                      <p className="underText">
                        Create ambiance with strategic lighting and color
                        choices
                      </p>
                    </div>

                    <div className="icons">
                      <img src={WorkingDrawings} alt="icon" />
                      <p className="icon_heading">Project Management</p>
                      <p className="underText">
                        Coordinate every detail to ensure timely and on-budget
                        delivery
                      </p>
                    </div>

                    <div className="icons">
                      <img src={Tendering} alt="icon" />
                      <p className="icon_heading">Client Collaboration</p>
                      <p className="underText">
                        Partner closely to bring your vision to life
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="services_row-img_wrapper mobile-hidden">
                  <img
                    src={InteriorImage}
                    className="services_row-img"
                    alt="Architecture"
                  />
                </Col>
              </Row>
            </Fade>
          </Container>

          <hr />

          <Fade bottom>
            <div className="showcase">
              <h1 className="section_heading section_heading2">
                Interior Design{" "}
                <span className="green_text_heading">Projects</span>
              </h1>

              <ProjectShowcase Project={InteriorDesignProjects} />
            </div>
          </Fade>

          <hr />
        </>
      )}
    </>
  );
};

export default ArchitectureInteriorComponent;
