import React, { useEffect } from "react";
import CustomNavbar from "../navbar";
import BannerSection from "../../aboutPage/bannerSection";
import GetInTouch from "../../landingPage/getInTouchSection";
import Footer from "../footer";
import { Fade } from "react-reveal";

const ServicesBoilerPlate = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <CustomNavbar />

      <BannerSection bannerImage={props.bannerImage} />

      {props.componentToRender === "Architecture&Interior" ? (
        <>{props.component}</>
      ) : (
        <>
          {props.componentToRender === "AntiqueWoodwork" ? (
            <>{props.component}</>
          ) : (
            <>
              {props.componentToRender === "MEP" ? (
                <>{props.component}</>
              ) : (
                <>
                  {props.componentToRender === "ThreeDVisualization" ? (
                    <>{props.component}</>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      <Fade top>
        <div className="mt-5">
          <GetInTouch />
        </div>
      </Fade>

      <Footer />
    </div>
  );
};
export default ServicesBoilerPlate;
