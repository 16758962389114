import CustomNavbar from "../../common/navbar";
import "./style.css";
import defaultBanner from "../../../assets/about-banner-img.png";

const BannerSection = (props) => {
  return (
    <div>
      <CustomNavbar />
      <div className="banner_wrapper">
        <img
          className="banner"
          src={props.bannerImage || defaultBanner}
          alt="banner"
        />
      </div>
    </div>
  );
};
export default BannerSection;
