import React, { useEffect } from "react";
import CustomNavbar from "../components/common/navbar";
import ProjectGalleryViewer from "../components/common/Proejcts/ProjectGalleryViewer";
import Footer from "../components/common/footer";
import { Fade } from "react-reveal";
import dashImage from "../assets/Nav → List → Item → Link (1).png";
import { useLocation, useNavigate } from "react-router-dom";
import GetInTouch from "../components/landingPage/getInTouchSection";

const ProjectGallery = () => {
  const location = useLocation();
  const projectName = location.state?.projectName;
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state?.projectName) {
      navigate("/");
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <CustomNavbar />
      <Fade top>
        <section style={{ marginTop: "150px" }}>
          <div className="top">
            <img src={dashImage} alt="dashImage" />
            <div>Project Gallery</div>
            <img src={dashImage} alt="dashImage" />
          </div>

          <h1 className="section_heading">{projectName}</h1>
        </section>
      </Fade>

      <ProjectGalleryViewer project={projectName} />

      {/* <Fade right>
        <GetInTouch />
      </Fade> */}
      <Footer />
    </>
  );
};

export default ProjectGallery;
