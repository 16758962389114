import React from "react";
import ServicesBoilerPlate from "../../components/common/Services/ServicesBoilerPlate";
import AntiqueWWBanner from "../../assets/antique woodwork/AntiqueWWBanner.png";
import AntiqueWoodworkComponent from "../../components/services/AntiqueWoodworkComponent";
import { Helmet } from "react-helmet";

const AntiqueWoodwork = () => {
  return (
    <>
      <Helmet>
        <title>
          Antique Woodwork | Heritage Craftsmanship in Chennai | Timeless
          Elegance
        </title>
        <meta
          name="description"
          content="Explore the allure of antique woodwork at our Chennai studio, where heritage craftsmanship meets modern elegance. Discover exquisite designs, unparalleled mastery, and market-leading expertise. From B2B to B2C, our creations are timely, budget-friendly, and infused with a customized heritage touch. View recent works, including the Exquisite Antique Chettinad Teak Wood Door and Natural Hand Carved Antique Wooden Pillar. Contact us for sustainable artistry that tells a past story with a green future.
"
        />
        <meta
          name="keywords"
          content="Antique woodwork, heritage craftsmanship, Chennai experts, timeless spaces, exquisite designs, craftsmanship mastery, B2B excellence, B2C excellence, timely and budget-friendly, heritage infusion, sustainable artistry, recent works, Chettinad Teak Wood Door, hand-carved wooden pillar, handcrafted carving design.
"
        />
      </Helmet>
      <ServicesBoilerPlate
        bannerImage={AntiqueWWBanner}
        componentToRender="AntiqueWoodwork"
        component={<AntiqueWoodworkComponent />}
      />
    </>
  );
};

export default AntiqueWoodwork;
