import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Offcanvas,
} from "react-bootstrap";
import logo from "../../../assets/Dark LOGO JPG 1.png";
import Instagram from "../../../assets/instagram.png";
import LinkedIn from "../../../assets/linkedin.png";
import Facebook from "../../../assets/facebook (2).png";
import "./style.css";

const CustomNavbar = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <Navbar
      className="custom_navbar_color mb-3"
      bg="dark"
      variant="dark"
      expand="lg"
      fixed="top"
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="start"
          className="custom_navbar_color bg-dark text-bg-dark "
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="ms-auto links mob_offcanvas_body">
              <Nav.Link
                as={Link}
                className={isActive("/") ? "active home" : "home"}
                to="/"
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                className={isActive("/about") ? "active about" : "about"}
                to="/about"
              >
                About Us
              </Nav.Link>

              <NavDropdown
                title="Services"
                className={
                  isActive("/services") ? "active services" : "services"
                }
                id="nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/architecture&interior">
                  Architecture & Interior Design
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/antique-woodwork">
                  Antique Woodwork
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/mep">
                  MEP
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/3d-visualization">
                  3D Visualization
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={Link}
                className={
                  isActive("/projects") ? "active projects" : "projects"
                }
                to="/projects"
              >
                Projects
              </Nav.Link>
              <Nav.Link
                as={Link}
                className={isActive("/contact") ? "active contact" : "contact"}
                to="/contact"
              >
                Contact Us
              </Nav.Link>

              <div className="social-network-icon">
                <a href="https://www.instagram.com/arcustyle/">
                  <img src={Instagram} alt="instagram-img" />
                </a>
                <a href="https://www.linkedin.com/company/96633840/admin/notifications/all/">
                  <img src={LinkedIn} alt="linkedin-img" />
                </a>
                <a href="https://www.facebook.com/Arcustyle">
                  <img src={Facebook} alt="facebook-img" />
                </a>
              </div>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
