import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import dashImage from "../../assets/Nav → List → Item → Link (1).png";
import AntiqueWoodwork from "../../assets/antique woodwork/antique woodwork.png";
import ExquisiteDesigns from "../../assets/antique woodwork/Exquisite Designs.png";
import CraftsmanshipMastery from "../../assets/antique woodwork/Craftsmanship Mastery.png";
import MarketLeading from "../../assets/antique woodwork/Market-Leading Expertise.png";
import B2BandB2C from "../../assets/antique woodwork/B2B and B2C Excellence.png";
import TimelyBudgetFriendly from "../../assets/antique woodwork/Timely & Budget-Friendly.png";
import HeritageTouch from "../../assets/antique woodwork/Heritage Touch.png";
import SustainableArtistry from "../../assets/antique woodwork/Sustainable Artistry.png";
import ProjectShowcase from "../common/Services/ProjectShowcase";
import AntiqueWoodwork1 from "../../assets/antique woodwork/1.png";
import AntiqueWoodwork2 from "../../assets/antique woodwork/2.png";
import AntiqueWoodwork3 from "../../assets/antique woodwork/3.png";
import { Fade } from "react-reveal";

const AntiqueWoodworkComponent = () => {
  const RecentWorks = [
    {
      img: AntiqueWoodwork1,
      project: "Exquisite Antique Chettinad Teak Wood Door",
    },
    {
      img: AntiqueWoodwork2,
      project: "Natural Hand Carved Antique Wooden Pillar",
    },
    {
      img: AntiqueWoodwork3,
      project: "Antique Handcrafted Carving Design",
    },
  ];
  return (
    <>
      <Container>
        <Fade top>
          <section>
            <div className="top mt-3">
              <img src={dashImage} alt="dashImage" />
              <div>Services We Do</div>
              <img src={dashImage} alt="dashImage" />
            </div>

            <h1 className="section_heading">
              Antique <span className="green_text_heading">Woodwork</span>
            </h1>
          </section>
        </Fade>

        <Fade left>
          <Row className="services_row align-items-center">
            <Col md={6} className="services_row-img_wrapper mobile-visible">
              <img
                src={AntiqueWoodwork}
                className="services_row-img"
                alt="Architecture"
              />
            </Col>

            <Col md={6} className="services_row-texts-and-icons">
              <div className="services_row-texts">
                <div className="services_row-text-1">
                  <span>Antique </span> Woodwork Elegance
                </div>

                <div className="services_row-text-2">
                  PRESERVING Heritage Craftsmanship <br />
                  for timeless spaces
                </div>
              </div>

              <div className="services_row-icons mb-5">
                <div className="icons">
                  <img src={ExquisiteDesigns} alt="icon" />
                  <p className="icon_heading">Conceptual Design</p>
                  <p className="underText">
                    Craft innovative interior concepts that resonate with your
                    space
                  </p>
                </div>

                <div className="icons">
                  <img src={CraftsmanshipMastery} alt="icon" />
                  <p className="icon_heading">Space Planning</p>
                  <p className="underText">
                    Optimize layouts for maximum functionality and aesthetics
                  </p>
                </div>

                <div className="icons">
                  <img src={MarketLeading} alt="icon" />
                  <p className="icon_heading">Material Selection</p>
                  <p className="underText">
                    Curate quality materials that align with your vision and
                    budget
                  </p>
                </div>

                <div className="icons">
                  <img src={B2BandB2C} alt="icon" />
                  <p className="icon_heading">Furniture & Furnishings</p>
                  <p className="underText">
                    Source and place stylish, ergonomic furniture and decor
                  </p>
                </div>

                <div className="icons">
                  <img src={TimelyBudgetFriendly} alt="icon" />
                  <p className="icon_heading">Lighting & Color</p>
                  <p className="underText">
                    Create ambiance with strategic lighting and color choices
                  </p>
                </div>

                <div className="icons">
                  <img src={HeritageTouch} alt="icon" />
                  <p className="icon_heading">Project Management</p>
                  <p className="underText">
                    Coordinate every detail to ensure timely and on-budget
                    delivery
                  </p>
                </div>

                <div className="icons">
                  <img src={SustainableArtistry} alt="icon" />
                  <p className="icon_heading">Client Collaboration</p>
                  <p className="underText">
                    Partner closely to bring your vision to life
                  </p>
                </div>
              </div>
            </Col>

            <Col md={6} className="services_row-img_wrapper mobile-hidden">
              <img
                src={AntiqueWoodwork}
                className="services_row-img"
                alt="Architecture"
              />
            </Col>
          </Row>
        </Fade>
      </Container>

      <hr />

      <Fade right>
        <div className="showcase">
          <h1 className="section_heading section_heading2">
            Antique Woodwork{" "}
            <span className="green_text_heading">Projects</span>
          </h1>

          <ProjectShowcase Project={RecentWorks} />
        </div>
      </Fade>

      <hr />
    </>
  );
};

export default AntiqueWoodworkComponent;
