import { useNavigate } from "react-router-dom";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";

const OurDesigns = ({ title, designs }) => {
  const navigate = useNavigate();

  const handleProjectViewer = (project) => {
    navigate("/view-project", { state: { projectName: project } });
  };

  return (
    <Container>
      <div className="residential-sec">
        <div className="residential-sec-heading">
          {title} <span>design</span>
        </div>
        <Row className="residential-sec-cards justify-content-center gap-3">
          {designs.map((design, index) => (
            <Col key={index} sm={6} className="residential-sec-card-img">
              <Fade right>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => handleProjectViewer(design.project)}
                  src={design.img}
                  alt={design.altText}
                />
              </Fade>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
};
export default OurDesigns;
