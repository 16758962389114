import { Col, Container, Row } from "react-bootstrap";
import BottomCorner from "../../common/bottomCorner";
import "./style.css";
import { Fade } from "react-reveal";

const InteriorSection = () => {
  return (
    <div className="section_wrapper">
      <Container>
        <Fade bottom>
          <Row className="section row gap-2 justify-content-center align-items-center">
            <Col md={6} className="left-section">
              <div className="left-section-texts">
                <div className="left-text-1">
                  Leading Interior Design
                  <br />
                  Innovators in Chennai, Elevating Spaces
                </div>
                <div className="left-text-2">
                  <span>We</span> Create Lasting Impressions Through Interior
                  Design
                </div>
                <div className="left-text-3">
                  At Arcustyle, our passion for architecture and design drives
                  us to collaborate closely with our clients, translating their
                  distinctive visions into tangible realities. Whether you
                  envision a bespoke residence, a revitalized workspace, or an
                  inviting retail haven, our expert team possesses the skills
                  and expertise to not only meet but exceed your expectations.
                </div>
                <div className="left-text-4">
                  Join us on an exhilarating journey as we redefine spaces and
                  craft environments that evoke enduring impressions. At
                  Arcustyle, we are more than architects; we are visionaries,
                  committed to making your dreams a concrete, aesthetic, and
                  functional masterpiece. Together, we transform the ordinary
                  into the extraordinary.
                </div>
              </div>
              {/* <div className="bottom_corner">
                <BottomCorner
                  bottomCornerHeight="16.8rem"
                  bottomCornerleft="-2rem"
                  bottomCornerTop="-15.5rem"
                  greenRectLeft="17.65rem"
                  greenRectTop="-15.75rem"
                />
              </div> */}
            </Col>
            <Col md={6} className="right-section"></Col>
          </Row>
        </Fade>
      </Container>
    </div>
  );
};
export default InteriorSection;
