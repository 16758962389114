import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PhotoView } from "react-photo-view";
import Cafe1 from "../../../assets/Project/Cafe/Cafe v1.jpg";
import Cafe2 from "../../../assets/Project/Cafe/Cafe v2.jpg";
import Cafe3 from "../../../assets/Project/Cafe/Cafe v3.jpg";
import Cafe4 from "../../../assets/Project/Cafe/Cafe v4.jpg";
import CasaConvida1 from "../../../assets/Project/Casa Convida/Casa Convida V1.jpg";
import CasaConvida2 from "../../../assets/Project/Casa Convida/Casa Convida V2.jpg";
import CasaConvida3 from "../../../assets/Project/Casa Convida/Casa Convida V3.jpg";
import DúnSolais1 from "../../../assets/Project/Dún Solais/Dún Solais V1.jpg";
import DúnSolais2 from "../../../assets/Project/Dún Solais/Dún Solais v2.jpg";
import DúnSolais3 from "../../../assets/Project/Dún Solais/Dún Solais v3.jpg";
import ExquisiteAntique1 from "../../../assets/Project/Exquisite Antique Chettinad Teak Wood Door/Exquisite Antique Chettinad Teak Wood Door V1.jpg";
import ExquisiteAntique2 from "../../../assets/Project/Exquisite Antique Chettinad Teak Wood Door/Exquisite Antique Chettinad Teak Wood Door V3.jpg";
import ExquisiteAntique3 from "../../../assets/Project/Exquisite Antique Chettinad Teak Wood Door/Exquisite Antique Chettinad Teak Wood Door v2.jpg";
import Harmonie1 from "../../../assets/Project/Harmonie/Harmonie v1.jpg";
import Harmonie2 from "../../../assets/Project/Harmonie/Harmonie v2.jpg";
import Harmonie3 from "../../../assets/Project/Harmonie/Harmonie v3.jpg";
import Harmonie4 from "../../../assets/Project/Harmonie/Harmonie v4.jpg";
import LeCharmant1 from "../../../assets/Project/Le Charmant/Le Charmant v1.jpg";
import LeCharmant2 from "../../../assets/Project/Le Charmant/Le Charmant v2.jpg";
import LeCharmant3 from "../../../assets/Project/Le Charmant/Le Charmant v3.jpg";
import LeCharmant4 from "../../../assets/Project/Le Charmant/Le Charmant v4.jpg";
import LeCharmant5 from "../../../assets/Project/Le Charmant/Le Charmant v5.jpg";
import LeCharmant6 from "../../../assets/Project/Le Charmant/Le Charmant v6.jpg";
import Modernität1 from "../../../assets/Project/Modernität/Modernität v1.jpg";
import Modernität2 from "../../../assets/Project/Modernität/Modernität v2.jpg";
import Modernität3 from "../../../assets/Project/Modernität/Modernität v3.jpg";
import Modernität4 from "../../../assets/Project/Modernität/Modernität v4.jpg";
import NaturalHandCarved1 from "../../../assets/Project/Natural Hand Carved Antique Wooden Pillar/Natural Hand Carved Antique Wooden Pillar V1.jpg";
import NaturalHandCarved2 from "../../../assets/Project/Natural Hand Carved Antique Wooden Pillar/Natural Hand Carved Antique Wooden Pillar V2.jpg";
import NaturalHandCarved3 from "../../../assets/Project/Natural Hand Carved Antique Wooden Pillar/Natural Hand Carved Antique Wooden Pillar v3.jpg";
import NaturalHandCarved4 from "../../../assets/Project/Natural Hand Carved Antique Wooden Pillar/Natural Hand Carved Antique Wooden Pillar V4.jpg";
import Office1 from "../../../assets/Project/Office/Office v1.jpg";
import Office2 from "../../../assets/Project/Office/Office v2.jpg";
import Office3 from "../../../assets/Project/Office/Office v3.jpg";
import Office4 from "../../../assets/Project/Office/Office v4.jpg";
import Office5 from "../../../assets/Project/Office/Office v5.jpg";
import Office6 from "../../../assets/Project/Office/Office v6.jpg";
import Office7 from "../../../assets/Project/Office/Office v7.jpg";
import Office8 from "../../../assets/Project/Office/Office v8.jpg";
import Office9 from "../../../assets/Project/Office/Office v9.jpg";
import Office10 from "../../../assets/Project/Office/Office v10.jpg";
import Sanskriti1 from "../../../assets/Project/Sanskriti/Sanskriti v1.jpg";
import Sanskriti2 from "../../../assets/Project/Sanskriti/Sanskriti v2.jpg";
import Sanskriti3 from "../../../assets/Project/Sanskriti/Sanskriti v3.jpg";
import Sanskriti4 from "../../../assets/Project/Sanskriti/Sanskriti v4.jpg";
import Sanskriti5 from "../../../assets/Project/Sanskriti/Sanskriti v5.jpg";
import Sanskriti6 from "../../../assets/Project/Sanskriti/Sanskriti v6.jpg";
import Sanskriti7 from "../../../assets/Project/Sanskriti/Sanskriti v7.jpg";
import Sanskriti8 from "../../../assets/Project/Sanskriti/Sanskriti v8.jpg";
import Sanskriti9 from "../../../assets/Project/Sanskriti/Sanskriti v9.jpg";
import Sanskriti10 from "../../../assets/Project/Sanskriti/Sanskriti v10.jpg";
import Sanskriti11 from "../../../assets/Project/Sanskriti/Sanskriti v11.jpg";
import Sanskriti12 from "../../../assets/Project/Sanskriti/Sanskriti v12.jpg";
import Sereno1 from "../../../assets/Project/Sereno/Sereno v1.jpg";
import Sereno2 from "../../../assets/Project/Sereno/Sereno v2.jpg";
import Sereno3 from "../../../assets/Project/Sereno/Sereno v3.jpg";
import Sereno4 from "../../../assets/Project/Sereno/Sereno v4.jpg";
import Sereno5 from "../../../assets/Project/Sereno/Sereno v5.jpg";
import Sereno6 from "../../../assets/Project/Sereno/Sereno v6.jpg";
import Eleganza1 from "../../../assets/Project/The Eleganza/The Eleganza v1.jpg";
import Eleganza2 from "../../../assets/Project/The Eleganza/The Eleganza v2.jpg";
import Eleganza3 from "../../../assets/Project/The Eleganza/The Eleganza v3.jpg";
import Eleganza4 from "../../../assets/Project/The Eleganza/The Eleganza v4.jpg";
import Innenraum1 from "../../../assets/Project/The Innenraum/The Innenraum v1.jpg";
import Innenraum2 from "../../../assets/Project/The Innenraum/The Innenraum v2.jpg";
import Innenraum3 from "../../../assets/Project/The Innenraum/The Innenraum v3.jpg";
import Innenraum4 from "../../../assets/Project/The Innenraum/The Innenraum v4.jpg";
import Opulenz1 from "../../../assets/Project/The Opulenz/The Opulenz v1.jpg";
import Opulenz2 from "../../../assets/Project/The Opulenz/The Opulenz V2.jpg";
import Opulenz3 from "../../../assets/Project/The Opulenz/The Opulenz v3.jpg";
import Rasoi1 from "../../../assets/Project/The Rasoi/The Rasoi v1.jpg";
import Rasoi2 from "../../../assets/Project/The Rasoi/The Rasoi v2.jpg";
import Rasoi3 from "../../../assets/Project/The Rasoi/The Rasoi v3.jpg";
import Rasoi4 from "../../../assets/Project/The Rasoi/The Rasoi v4.jpg";
import Showroom1 from "../../../assets/Project/showroom/showroom v1.jpg";
import Showroom2 from "../../../assets/Project/showroom/showroom v2.jpg";
import Showroom3 from "../../../assets/Project/showroom/showroom v3.jpg";
import Showroom4 from "../../../assets/Project/showroom/showroom v4.jpg";
import Showroom5 from "../../../assets/Project/showroom/showroom v5.jpg";
import Showroom6 from "../../../assets/Project/showroom/showroom v6.jpg";
import Showroom7 from "../../../assets/Project/showroom/showroom v7.jpg";
import Showroom8 from "../../../assets/Project/showroom/showroom v8.jpg";
import Showroom9 from "../../../assets/Project/showroom/showroom v9.jpg";
import Showroom10 from "../../../assets/Project/showroom/showroom v10.jpg";
import Showroom11 from "../../../assets/Project/showroom/showroom v11.jpg";
import Showroom12 from "../../../assets/Project/showroom/showroom v12.jpg";
import AntiqueHandcrafted1 from "../../../assets/Project/antique handcrafted carving design/antique handcrafted carving design V1.jpg";
import AntiqueHandcrafted2 from "../../../assets/Project/antique handcrafted carving design/antique handcrafted carving design V2.jpg";
import AntiqueHandcrafted3 from "../../../assets/Project/antique handcrafted carving design/antique handcrafted carving design V3.jpg";
import AntiqueHandcrafted4 from "../../../assets/Project/antique handcrafted carving design/antique handcrafted carving design V4.jpg";
import AntiqueHandcrafted5 from "../../../assets/Project/antique handcrafted carving design/antique handcrafted carving design V5.jpg";
import "./style.css";
import { Fade } from "react-reveal";

const ProjectGalleryViewer = (props) => {
  const [project, setProject] = useState([]);
  const projectName = props.project;

  const Cafe = [Cafe1, Cafe2, Cafe3, Cafe4];
  const CasaConvida = [CasaConvida1, CasaConvida2, CasaConvida3];
  const DunSolais = [DúnSolais1, DúnSolais2, DúnSolais3];
  const ExquisiteAntique = [
    ExquisiteAntique1,
    ExquisiteAntique2,
    ExquisiteAntique3,
  ];
  const Harmonie = [Harmonie1, Harmonie2, Harmonie3, Harmonie4];
  const LeCharmant = [
    LeCharmant1,
    LeCharmant2,
    LeCharmant3,
    LeCharmant4,
    LeCharmant5,
    LeCharmant6,
  ];
  const Modernität = [Modernität1, Modernität2, Modernität3, Modernität4];
  const NaturalHandCarved = [
    NaturalHandCarved1,
    NaturalHandCarved2,
    NaturalHandCarved3,
    NaturalHandCarved4,
  ];
  const Office = [
    Office1,
    Office2,
    Office3,
    Office4,
    Office5,
    Office6,
    Office7,
    Office8,
    Office9,
    Office10,
  ];

  const Sanskriti = [
    Sanskriti1,
    Sanskriti2,
    Sanskriti3,
    Sanskriti4,
    Sanskriti5,
    Sanskriti6,
    Sanskriti7,
    Sanskriti8,
    Sanskriti9,
    Sanskriti10,
    Sanskriti11,
    Sanskriti12,
  ];
  const Sereno = [Sereno1, Sereno2, Sereno3, Sereno4, Sereno5, Sereno6];
  const Eleganza = [Eleganza1, Eleganza2, Eleganza3, Eleganza4];
  const Innenraum = [Innenraum1, Innenraum2, Innenraum3, Innenraum4];
  const Opulenz = [Opulenz1, Opulenz2, Opulenz3];
  const Rasoi = [Rasoi1, Rasoi2, Rasoi3, Rasoi4];
  const Showroom = [
    Showroom1,
    Showroom2,
    Showroom3,
    Showroom4,
    Showroom5,
    Showroom6,
    Showroom7,
    Showroom8,
    Showroom9,
    Showroom10,
    Showroom11,
    Showroom12,
  ];
  const AntiqueHandcrafted = [
    AntiqueHandcrafted1,
    AntiqueHandcrafted2,
    AntiqueHandcrafted3,
    AntiqueHandcrafted4,
    AntiqueHandcrafted5,
  ];

  useEffect(() => {
    if (projectName === "Cafe") {
      setProject(Cafe);
    } else if (projectName === "Casa Convida") {
      setProject(CasaConvida);
    } else if (projectName === "Dún Solais") {
      setProject(DunSolais);
    } else if (projectName === "Exquisite Antique Chettinad Teak Wood Door") {
      setProject(ExquisiteAntique);
    } else if (projectName === "Harmonie") {
      setProject(Harmonie);
    } else if (projectName === "Le Charmant") {
      setProject(LeCharmant);
    } else if (projectName === "Modernität") {
      setProject(Modernität);
    } else if (projectName === "Natural Hand Carved Antique Wooden Pillar") {
      setProject(NaturalHandCarved);
    } else if (projectName === "Office") {
      setProject(Office);
    } else if (projectName === "Sanskriti") {
      setProject(Sanskriti);
    } else if (projectName === "Sereno") {
      setProject(Sereno);
    } else if (projectName === "The Eleganza") {
      setProject(Eleganza);
    } else if (projectName === "The Innenraum") {
      setProject(Innenraum);
    } else if (projectName === "The Opulenz") {
      setProject(Opulenz);
    } else if (projectName === "The Rasoi") {
      setProject(Rasoi);
    } else if (projectName === "Showroom") {
      setProject(Showroom);
    } else if (projectName === "Antique Handcrafted Carving Design") {
      setProject(AntiqueHandcrafted);
    }
  }, [projectName]);

  return (
    <Container className="gallery_img_wrapper">
      <Row className="residential-sec-cards justify-content-center gap-3 ">
        {project.map((img, index) => (
          <Col sm={6} className="residential-sec-card-img">
            <Fade right>
              <PhotoView src={img}>
                <img
                  className="gallery_img"
                  style={{ cursor: "pointer" }}
                  src={img}
                  alt={projectName + index}
                />
              </PhotoView>
            </Fade>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ProjectGalleryViewer;
