import "./style.css";
import dashImage from "../../../assets/Nav → List → Item → Link (1).png";
import { Col, Container, Row } from "react-bootstrap";
import WhoWeARE from "../../../assets/whoweare-left-img.png";
import { Fade } from "react-reveal";

const WhoWeAreSec = () => {
  return (
    <Container>
      <Fade bottom>
        <Row className="whoweare-section gap-4">
          <Col md={7} className="whoweare-left-section"></Col>
          <Col md={4} className="whoweare-right-section">
            <div className="top">
              <img src={dashImage} alt="dashImage" />
              <div>Who</div>
              <img src={dashImage} alt="dashImage" />
            </div>

            <div className="bottom mb-5">We Are ?</div>
            <img className="whoweare_MOBimg" src={WhoWeARE} alt="" />
            <div className="whoweare-right-section-content">
              Arcustyle, established in 2023, is a pioneering architectural
              design firm dedicated to shaping the future of spaces. We are
              fueled by a relentless pursuit of the extraordinary and an
              unwavering commitment to pushing the boundaries of architectural
              innovation. Our team of architects and visionaries thrives on
              creating lasting impressions through cutting-edge designs that
              seamlessly blend form and function. Arcustyle doesn't just build
              structures; we craft experiences that captivate and inspire,
              leaving an indelible mark on the world of architecture .
              <div className="mt-4">Our Philosophy</div> At Arcustyle, we
              believe in the transformative power of design. Our philosophy is
              rooted in the extraordinary, where innovation meets imagination.
              With an unyielding dedication to excellence, we meticulously craft
              each project to reflect the values and aspirations of our clients.
              Join us on this journey to redefine spaces, challenge conventions,
              and leave a legacy of architectural excellence. Arcustyle is where
              visionary spaces become a reality.
            </div>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
};
export default WhoWeAreSec;
