import "./style.css";
import CustomNavbar from "../navbar";
import TopCorner from "../top-right-corner-design";
import ViewProjectsBtn from "../viewProjectsBtn";
import { Container } from "react-bootstrap";
import { Zoom } from "react-reveal";

const Header = () => {
  return (
    <div className="bg-image">
      {/* //navbar */}
      <CustomNavbar />
      <Container>
        <div className="hero_section_wrapper ">
          <Zoom>
            <div className="banner-text mt-5">
              <div className="text-1">
                <span>Elegant</span> Room Transformations
              </div>
              <div className="text-2">Creative Dream Spaces</div>
              <div className="text-3">
                Discover our renowned bespoke design services, where meticulous
                attention to detail defines each
                <br /> project. Our creative and receptive approach ensures
                unique solutions, setting us apart in delivering
                <br /> exceptional design experiences.
              </div>

              <div className="view_project_btn">
                <ViewProjectsBtn link="/projects" text="View Projects" />
              </div>
            </div>
          </Zoom>

          {/* <div className="top_corner">
            <TopCorner />
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default Header;
