import { useEffect } from "react";
import BannerSection from "../components/aboutPage/bannerSection";
import DesignMasterySec from "../components/aboutPage/designMasterySection";
import WhoWeAreSec from "../components/aboutPage/whoWeAreSection";
import WhyToChoose from "../components/aboutPage/whyToChooseSection";
import Footer from "../components/common/footer";
import GetInTouch from "../components/landingPage/getInTouchSection";
import WhatWeDo from "../components/landingPage/whatWeDoSection";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          Arcustyle: Shaping the Future of Spaces with Innovative Architectural
          Design
        </title>
        <meta
          name="description"
          content="Discover Arcustyle, a pioneering architectural design firm established in 2023. Immerse in our philosophy rooted in innovation, where design meets imagination. Explore our expertise in architecture, interior design, MEP services, and cutting-edge 3D visualization. Choose Arcustyle for excellence, efficiency, and inspired spaces"
        />
      </Helmet>
      <BannerSection />
      <WhoWeAreSec />
      {/* our services */}
      <WhatWeDo title="Our Services" />
      <WhyToChoose />
      <Fade left>
        <DesignMasterySec />
      </Fade>
      <Fade right>
        <div className="about_get_in_touch">
          <GetInTouch />
        </div>
      </Fade>
      <Footer />
    </div>
  );
};

export default About;
