import "./style.css";
import dashImage from "../../../assets/Nav → List → Item → Link (1).png";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import React from "react";
import panoramicImage from "../../../assets/panoramic-img.png";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";

const PanoramicView = () => {
  return (
    <Container>
      <div className="panoramic-section">
        <Fade top>
          <div className="panoramic-heading-section">
            <div className="top">
              <img src={dashImage} alt="dashImage" />
              <div>Check Out</div>
              <img src={dashImage} alt="dashImage" />
            </div>

            <div className="bottom">panoramic View 360°</div>
          </div>
        </Fade>

        <Fade bottom>
          <div className="container mt-4 d-flex justify-content-center">
            <ReactPhotoSphereViewer
              src={panoramicImage}
              height={"80vh"}
              width={"100%"}
            />
          </div>
        </Fade>
      </div>
    </Container>
  );
};
export default PanoramicView;
