import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Error404 from "./pages/Error404";
import ArchitectureInteriorDesign from "./pages/Services/ArchitectureInteriorDesign";
import AntiqueWoodwork from "./pages/Services/AntiqueWoodwork";
import MEP from "./pages/Services/MEP";
import ThreeDVisualization from "./pages/Services/ThreeDVisualization";
import ProjectGallery from "./pages/ProjectGallery";
import Preloader from "./components/common/Preloader/Preloader";
import { useEffect, useState } from "react";

function App() {
  const [showPreloader, setshowPreloader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setshowPreloader(false);
    }, 4000);
  }, [showPreloader]);

  return (
    <div className="overflow_hide">
      {showPreloader ? (
        <Preloader />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/projects" element={<Projects />} />
            <Route
              path="/architecture&interior"
              element={<ArchitectureInteriorDesign />}
            />
            <Route path="/antique-woodwork" element={<AntiqueWoodwork />} />
            <Route path="/mep" element={<MEP />} />
            <Route path="/3d-visualization" element={<ThreeDVisualization />} />
            <Route path="/view-project" element={<ProjectGallery />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
